import Modal from "../components/Modal/Modal";
import { Form, Input, Row, Col, InputNumber } from "antd";

import Select from "../components/Select/Select";
import Button from "../components/Button";
import { useState } from "react";

import { Mode, ProviderDetailes } from "../utils/types";
import { IDS } from "../utils/elementsIds";

import { emailValidator, validateNegativeField } from "../utils/utils";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";

interface AdvertisersModalProps {
  close: () => void;
  data?: ProviderDetailes;
  onFinish: (provider: ProviderDetailes) => void;
  mode: Mode;
}

const ProviderModal = (props: AdvertisersModalProps) => {
  const { data, close, onFinish, mode } = props;
  const [method, setMethod] = useState<string>("");

  const [secheduler, setScheduler] = useState<Dayjs | null>(
    dayjs(data?.scheduler) || dayjs()
  );
  const [form] = Form.useForm();
  const fieldsToCheck = [
    "tag_id_index",
    "revenue_index",
    "imps_index",
    "ad_requests_index",
  ];
  const findKeysWithDuplicateValues = () => {
    let seenValues: any = {};
    let duplicateKeys: string[] = [];
    let fields = form.getFieldsValue();
    let object: any = {
      tag_id_index: fields.tag_id_index,
      revenue_index: fields.revenue_index,
      imps_index: fields.imps_index,
      ad_requests_index: fields.ad_requests_index,
    };
    for (const key in object) {
      const value = object[key];
      if (value) {
        if (seenValues[value] !== undefined) {
          duplicateKeys.push(seenValues[value], key);
        } else {
          seenValues[value] = key;
        }
      }
    }

    return duplicateKeys;
  };

  const onFieldsChange = () => {
    form.setFields([
      { name: "tag_id_index", errors: [] },
      { name: "revenue_index", errors: [] },
      { name: "imps_index", errors: [] },
      { name: "ad_requests_index", errors: [] },
    ]);

    let keysArr = findKeysWithDuplicateValues();
    let formFields = [];
    for (let key of keysArr) {
      if (fieldsToCheck.includes(key)) {
        formFields.push({
          name: key,
          errors: ["Value exsist on mapping fields"],
        });
      }
    }
    form.setFields(formFields);
  };
  return (
    <Modal
      width={"60%"}
      title={<h2>{mode === "new" ? "Add New Provider" : "Edit Provider"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
      removeCloseButton={true}
    >
      <Form
        onFieldsChange={onFieldsChange}
        form={form}
        name="basic"
        onFinish={async (provider: ProviderDetailes) => {
          let validateErrors = form
            .getFieldsError(fieldsToCheck)
            .filter((field) => field.errors.length > 0);
          if (validateErrors.length === 0) {
            provider.scheduler = secheduler;
            if (mode === "edit" && data)
              provider.provider_id = data?.provider_id;
            onFinish(provider);
          }
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
        layout="vertical"
        requiredMark
        {...(data && { initialValues: data })}
      >
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Provider Name",
                },
              ]}
            >
              <Input placeholder="Type Provider Name" size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Contact Name"
              name="contact_name"
              rules={[
                {
                  required: true,
                  message: "Type Contact Name",
                },
              ]}
            >
              <Input placeholder="Type Contact Name" size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Contact Email"
              name="contact_email"
              rules={[
                {
                  required: true,
                  message: "Choose Contact Email",
                },
                { validator: emailValidator },
              ]}
            >
              <Select
                allowClear
                placeholder="Type emails"
                options={[]}
                mode="tags"
                showArrow={false}
                dropdownStyle={{ display: "none" }}
                open={false}
              ></Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Revshare"
              name="revshare_percentage"
              rules={[
                {
                  required: true,
                  message: "Choose Revshare",
                },
              ]}
            >
              <InputNumber
                size="large"
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value!.replace("%", "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Report method"
              name="report_method"
              rules={[
                {
                  required: true,
                  message: " Choose Report method",
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Select Report method"
                options={["API", "Mail"].map((tag: any) => ({
                  value: tag,
                  label: tag,
                }))}
                onChange={(value: any) => {
                  setMethod(value);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Type Username",
                },
              ]}
            >
              <Input placeholder="Type Username" size="large" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Type Password",
                },
              ]}
            >
              <Input placeholder="Type Password" size="large" />
            </Form.Item>
          </Col>

          {/* {(method === "API" ||
            (data?.report_method === "API" && method != "Mail")) && (
            <Col span={6}>
              <Form.Item
                label="Scheduler"
                // name="scheduler"
                rules={[
                  {
                    required: true,
                    message: "Choose Scheduler",
                  },
                ]}
              >
                <TimePicker
                  format={"HH:mm"}
                  onChange={(time: Dayjs | null) => {
                    setScheduler(time);
                  }}
                  value={secheduler}
                />
              </Form.Item>
            </Col>
          )} */}

          <Col span={6}>
            <Form.Item
              label="Rows to skip"
              name="rows_to_skip"
              rules={[
                {
                  validator: validateNegativeField,
                  message: "Value needs to be > 1",
                },
              ]}
            >
              <InputNumber
                placeholder="Type Rows To Skip"
                size="large"
                onChange={(value: any) => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Placement"
              name="tag_id_index"
              rules={[
                {
                  required: true,
                  message: "Type Placement",
                },
                {
                  validator: validateNegativeField,
                  message: "Value needs to be > 1",
                },
              ]}
            >
              <InputNumber
                // min={1}
                placeholder="Type Placement"
                size="large"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Revenue"
              name="revenue_index"
              rules={[
                {
                  required: true,
                  message: "Type Revenue",
                },
                {
                  validator: validateNegativeField,
                  message: "Value needs to be > 1",
                },
              ]}
            >
              <InputNumber
                placeholder="Type Revenue"
                size="large"
                onChange={(value: any) => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Imps"
              name="imps_index"
              rules={[
                {
                  required: true,
                  message: "Type Imps",
                },
                {
                  validator: validateNegativeField,
                  message: "Value needs to be > 1",
                },
              ]}
            >
              <InputNumber
                placeholder="Type Imps"
                size="large"
                onChange={(value: any) => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Ad Requests"
              name="ad_requests_index"
              rules={[
                {
                  required: true,
                  message: "Type Ad Requests",
                },
                {
                  validator: validateNegativeField,
                  message: "Value needs to be > 1",
                },
              ]}
            >
              <InputNumber
                placeholder="Type Ad Requests"
                size="large"
                onChange={(value: any) => {}}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", gap: "16px" }}>
          <Button type="default" title="Cancel" onClick={close}></Button>
          <Button
            id={IDS.PROVIDERS.ADD_BUTTON}
            type="primary"
            title={mode === "new" ? "Add Provider" : "Save changes"}
            htmlType="submit"
          ></Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProviderModal;
