import Modal from "../components/Modal/Modal";
import { Col, Form, Input, Image, Row } from "antd";
import PublisherSelect from "../pages/DomainSelection/PublisherSelect";
import DomainSelect from "../pages/DomainSelection/DomainSelect";
import Select from "../components/Select/Select";
import Button from "../components/Button";
import { useState } from "react";
import { Mode } from "../utils/types";
import { Scheduler } from "../utils/types";
import { IDS } from "../utils/elementsIds";
import instagramIcon from "../assets/images/instagram.svg";
import tiktokIcon from "../assets/images/tiktok.svg";
import twittterIcon from "../assets/images/twitter.svg";
interface NewSchedulerModalProps {
  close: () => void;
  onFinish: (scheduler: any) => void;
  mode: Mode;
  data?: Scheduler;
}

const NewSchedulerModal = (props: NewSchedulerModalProps) => {
  const { close, onFinish, mode, data } = props;
  const [scheduler, setScheduler] = useState<Scheduler>(data ? {...data, platforms: data.platform } : {} as Scheduler);
  const platformOptions = [
    {
      key: 1,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={instagramIcon} preview={false} />
          Instagram
        </div>
      ),
      value: "instagram",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms.includes("instagram"),
    },
    {
      key: 2,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={tiktokIcon} preview={false} />
          Tiktok
        </div>
      ),
      value: "tiktok",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms?.includes("tiktok"),
    },
    {
      key: 3,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={twittterIcon} preview={false} />
          Twitter
        </div>
      ),
      value: "twitter",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platforms?.includes("twitter_legacy"),
    },
    {
      key: 4,
      label: (
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <Image src={twittterIcon} preview={false} />
          Twitter Legacy
        </div>
      ),
      value: "twitter_legacy",
      disabled:
        scheduler.platforms?.length > 0 &&
        !scheduler.platform?.includes("twitter_legacy"),
    },
  ],
  initialValues = {
    username: scheduler.username ?? "",
    platforms: scheduler.platforms ? [scheduler.platforms] : [],
    rate: scheduler.rate ?? "",
    widget_id: scheduler.widget_id ?? null
  };
  
  return (
    <Modal
      width={"60%"}
      title={<h2>{mode === "new" ? "Add New Scheduler" : "Edit Scheduler"}</h2>}
      isModalOpen={true}
      setIsModalOpen={close}
      hideCncelButton
      hideOkButton
    >
      <Form name="basic" autoComplete="off" layout="vertical" requiredMark onFinish={() => onFinish(scheduler)} initialValues={initialValues}>
        <Row gutter={[24, 24]}>

          {mode === "new" && (<>
          <Col span={8}>
            <Form.Item
              label="Choose Publisher"
              name="publisher"
              rules={[
                {
                  required: !scheduler.pub_id,
                  message: "Please select publisher",
                },
              ]}
            >
              <PublisherSelect
                id={IDS.NEW_TAG.PUBLISHER_SELECT}
                mode="new"
                onSelect={(pub_id) => {
                  setScheduler({
                    ...scheduler,
                    pub_id: pub_id,
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Choose Domain"
              name="domain"
              rules={[
                {
                  required: !scheduler.domain_id,
                  message: "Please select domain",
                },
              ]}
            >
              <DomainSelect
                id={IDS.NEW_TAG.DOMAIN_SELECT}
                onSelect={(value: any) => {
                  setScheduler({
                    ...scheduler,
                    domain_id: value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          </>)}
          <Col span={8}>
            <Form.Item
              label="Type Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please type username",
                },
              ]}
              normalize={(value) => {
                return value.startsWith("@") || value === ""
                  ? value
                  : `@${value}`;
              }}
            >
              <Input
                style={{ height: "40px" }}
                placeholder="Type @username"
                value={scheduler.username}
                onChange={(e) => {
                  if (e.target.value.includes("@")) {
                    setScheduler({
                      ...scheduler,
                      username: e.target.value,
                    });
                  } else {
                    if (e.target.value !== "")
                      setScheduler({
                        ...scheduler,
                        username: "@" + e.target.value,
                      });
                    else
                      setScheduler({
                        ...scheduler,
                        username: "",
                      });
                  }
                }}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Type Widget Id"
              name="widget_id"
            >
              <Input
                style={{ height: "40px" }}
                placeholder="Type Widget Id"
                value={scheduler.widget_id ?? undefined}
                onChange={(e) => {
                  if (e.target.value !== "")
                    setScheduler({
                      ...scheduler,
                      widget_id: Number(e.target.value),
                    });
                  else
                    setScheduler({
                      ...scheduler,
                      widget_id: null,
                    });
                }}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Select Platform"
              name="platforms"
              rules={[
                {
                  required: true,
                  message: "Please select platform",
                },
              ]}
            >
              <Select
                placeholder="Select platform"
                mode="tags"
                onChange={(value: string[]) => {
                  setScheduler({
                    ...scheduler,
                    platforms: [...value],
                  });
                }}
                value={scheduler.platforms}
                options={scheduler.platforms?.length > 0 ? [] : platformOptions}
              ></Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Select Rate"
              name="rate"
              rules={[
                {
                  required: true,
                  message: "Please select rate",
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Rate"
                options={[
                  { value: "daily", label: "Daily" },
                  { value: "weekly", label: "Weekly" },
                  { value: "monthly", label: "Monthly" },
                ].map((item: any) => {
                  return { value: item.value, label: item.label };
                })}
                onChange={(value) =>
                  setScheduler({
                    ...scheduler,
                    rate: value,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Button
            type="primary"
            title={mode === "new" ? "Add Scheduler" : "Edit Scheduler"}
            htmlType="submit"
          ></Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default NewSchedulerModal;
