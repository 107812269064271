import React, { useEffect, useState } from "react";
import {
  Row,
  Table,
  Input,
  Tooltip,
  Popconfirm,
  notification
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import PureCard from "../../components/PureCard/PureCard";
import {
  getPublishers,
  insertPublisher,
  updatePublisher,
} from "../../api/services/Publishers";
import { Publisher } from "../../utils/types";
import { SearchOutlined } from "@ant-design/icons";
import PublisherDetails from "./PublisherDetails";
import Button from "../../components/Button";
import { EditIcon } from "../../assets/images/edit";
import { DeleteIcon } from "../../assets/images/delete";
import { useSelector } from "react-redux";
import { AppState } from "../../store/store";
import { COLORS } from "../../utils/colors.js";
import PublisherModal from "../../modals/PublisherModal";
import { NotificationType } from "../../utils/notifictions";
import { IDS } from "../../utils/elementsIds";
import { useAuth0 } from "@auth0/auth0-react";

import "./styles.scss";
import { FormatDateConvert } from "../../utils/utils";

const Publishers = () => {
  const [data, setData] = useState<Publisher[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showNewPublisher, setShowNewPublisher] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const { user } = useAuth0();

  const [selectedRecord, setSelectedRecord] = useState<Publisher>(
    {} as Publisher
  );
  const isDarkMode = useSelector((state: AppState) => state.isDarkMode);

  useEffect(() => {
    getPublishersData();
  }, []);
  const columns: any = [
    {
      title: "Publisher Name",
      dataIndex: "pub_name",
    },
    {
      title: "Create Date",
      dataIndex: "pub_date_created",
      defaultSortOrder: "descend",
      sorter: {
        compare: (a: any, b: any) =>
          new Date(a.pub_date_created).getTime() -
          new Date(b.pub_date_created).getTime(),
      },
    },
    {
      title: (
        <div className="center-column-title">
          <span>Active</span>
        </div>
      ),
      dataIndex: "is_active",
      render: (_: any, record: Publisher) => (
        <Row style={{ justifyContent: "center" }}>
          {record.is_active ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
          ) : (
            <CloseCircleOutlined style={{ color: "red", fontSize: 20 }} />
          )}
        </Row>
      ),
    },
    {
      title: <div className="center-column-title">Operation</div>,
      dataIndex: "operation",
      width: "15%",
      render: (_: any, record: Publisher) => (
        <Row
          style={{
            justifyContent: "space-evenly",
            width: "100%",
            alignSelf: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Button
            permission="update:publishers"
            type="default"
            onClick={() => {
              setSelectedRecord(record);
              setShowEditModal(true);
            }}
            icon={
              <Tooltip
                placement="topLeft"
                title={"Edit"}
                overlayStyle={{ maxWidth: "400px" }}
              >
                <span className="icon">
                  <EditIcon
                    color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                  />
                </span>
              </Tooltip>
            }
          ></Button>
          <Popconfirm
            title="Delete"
            style={{ background: "red" }}
            description="Are you sure you want to delete the Publisher?"
            placement="topLeft"
            onConfirm={async () => {
              // await deletePublisher(record.pub_id);
              // openNotificationWithIcon(
              //   "success",
              //   "Publisher deleted successfully"
              // );
              getPublishersData();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="default"
              onClick={() => {}}
              icon={
                <Tooltip
                  placement="topLeft"
                  title={"Delete"}
                  overlayStyle={{ maxWidth: "400px" }}
                >
                  <span className="icon">
                    <DeleteIcon
                      color={isDarkMode ? COLORS.darkMode.main : "#262626"}
                    />
                  </span>
                </Tooltip>
              }
            ></Button>{" "}
          </Popconfirm>
        </Row>
      ),
    },
  ];
  const openNotificationWithIcon = (
    type: NotificationType,
    message: string
  ) => {
    api[type]({
      message: message,
    });
  };

  const getPublishersData = async () => {
    setLoading(true);
    let response = await getPublishers();
    let originData: any[] = response.map((pub: Publisher) => {
      const dateObject = new Date(pub.publisher_date_created);
      const formattedDate = FormatDateConvert(dateObject);

      return {
        pub_id: pub.pub_id,
        pub_name: pub.pub_name,
        public_key: pub.public_key,
        is_active: pub.is_active,
        pub_date_created: formattedDate,
        pub_contact_name: pub.pub_contact_name,
        pub_email: pub.pub_email,
        contract_type: pub.contract_type,
        cpm: pub.cpm,
        location: pub.location,
        terms_of_payment_in_days: pub.terms_of_payment_in_days,
        dashboard_popup_message_url: pub.dashboard_popup_message_url,
        InCorporated: pub.InCorporated,
        websites: pub.websites,
        publisher_cpm: pub.publisher_cpm,
        publisher_serving_fee: pub.publisher_serving_fee,
        revenue_source: pub.revenue_source
      };
    });
    setData(originData);
    setLoading(false);
  };

  const close = () => {
    setShowNewPublisher(false);
    setShowEditModal(false);
  };
  const onFinishAddNew = async (publisher: Publisher) => {
    close();
    if (user) {
      try {
        let response = await insertPublisher(publisher, user);
        if (response.status && response.status === "publisher exist") {
          openNotificationWithIcon(
            "warning",
            "You've already created this publisher"
          );
        } else {
          openNotificationWithIcon("success", "Publisher added successfully");
          getPublishersData();
        }
      } catch {
        openNotificationWithIcon("error", "Something went wrong");
      }
    } else {
      openNotificationWithIcon("error", "No user is connected");
    }
  };

  const onFinishEdit = async (publisher: any) => {
    close();
    if (user) {
      try {
        let res = await updatePublisher(publisher, user);
        if (res) {
          openNotificationWithIcon("success", "Publisher updated successfully");
          getPublishersData();
        }
      } catch {
        openNotificationWithIcon("error", "Something went wrong");
      }
    } else {
      openNotificationWithIcon("error", "No user is connected");
    }
  };

  return (
    <PureCard>
      <div className="publishers">
        {contextHolder}
        <Row className="pub-row">
          <div style={{ width: "50%" }}>
            <Input
              addonBefore={<SearchOutlined />}
              size="large"
              id={IDS.PUBLISHERS.PUBLISHER_SEARCH}
              style={{ marginBottom: 24, width: "60%" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <Button
            permission="update:publishers"
            title="Add New Publisher"
            type="primary"
            size="large"
            onClick={() => {
              setShowNewPublisher(true);
            }}
            icon={<PlusCircleOutlined />}
          />
        </Row>
        <Table
          bordered
          dataSource={data.filter((record) => {
            return (
              record.pub_name
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              record.websites?.toLowerCase().includes(searchValue.toLowerCase())
            );
          })}
          rowKey={"pub_id"}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              <PublisherDetails publisherData={record} />
            ),
          }}
          loading={loading}
        />

        {showNewPublisher && (
          <PublisherModal
            mode={"new"}
            onFinish={onFinishAddNew}
            close={() => setShowNewPublisher(false)}
          />
        )}
        {showEditModal && (
          <PublisherModal
            onFinish={onFinishEdit}
            mode={"edit"}
            close={close}
            data={selectedRecord}
          />
        )}
      </div>
    </PureCard>
  );
};

export default Publishers;
