import { Row, Input, Form, Tooltip, Checkbox } from "antd";
import { Publisher } from "../../utils/types";
import TextArea from "antd/es/input/TextArea";
import DomainsTable from "./DomainTablesModal";
import { InfoCircleOutlined } from "@ant-design/icons";

interface PublisherModalProps {
  publisherData: Publisher;
}

const PublisherDetails = (props: PublisherModalProps) => {
  const { publisherData } = props;

  let contract_type = "none";

  switch (publisherData.contract_type) {
    case 3:
      contract_type = "Rev Share";
      break;
    case 4:
      contract_type = "Tech Fee";
      break;
    case 5:
      contract_type = "CPM";
      break;  
    case 6:
      contract_type = "Serving Fee";
      break;           
  }

  return (
    <div>
      <Form
        name="publisher-details"
        autoComplete="off"
        layout="vertical"
        requiredMark
        style={{ paddingRight: "10%", paddingLeft: "5.5%" }}
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Form.Item
            label="Contact Name"
            style={{ width: "17%", marginRight: 1.8 }}
          >
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "90%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.pub_contact_name || "none"}
            />
          </Form.Item>
          <Form.Item label="Publisher Email" style={{ width: "29%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "95%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.pub_email || "none"}
            />
          </Form.Item>
          <Form.Item label="Contract type" style={{ width: "16.5%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={contract_type}
            />
          </Form.Item>
          <Form.Item label="Rev Share" style={{ width: "12%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "90%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={(publisherData.cpm * 100).toFixed(1) + " %"}
            />
          </Form.Item>
        </Row>
        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Form.Item label="Terms Of Payment" style={{ width: "19%" }}>
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "90%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.terms_of_payment_in_days || "none"}
            />
          </Form.Item>
          <Form.Item label="Location" style={{ width: "29%", marginLeft: 3 }}>
            <>
            <Input
              readOnly={true}
              size="large"
              style={{
                width: "calc(100% - 30px)",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.location || "none"}
            />
              <Tooltip
                placement="topLeft"
                title={
                  <span>
                    Publisher Id: {publisherData.pub_id} <br />
                    Public Key: {publisherData.public_key}
                  </span>
                }
              >
                <InfoCircleOutlined style={{ fontSize: "20px", marginLeft: "10px" }} />
              </Tooltip>
            </>  
          </Form.Item>
          <Form.Item
            label="Dashbboard Message Pop-Up"
            style={{ width: "37%", paddingLeft: 0.8 }}
          >
            <TextArea
              readOnly={true}
              size={
                publisherData.dashboard_popup_message_url ? "large" : "small"
              }
              style={{
                width: "97%",
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={
                publisherData.dashboard_popup_message_url || "No popup Message"
              }
            />
          </Form.Item>
        </Row>


        <Row
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Form.Item style={{ width: "20%" }}>
            <Checkbox
              checked={publisherData.InCorporated}
              defaultChecked={publisherData.InCorporated}
              onChange={() => {}}
            >
              Is EU InCorporated
            </Checkbox>
          </Form.Item>


          <Form.Item label="CPM" style={{  width: "20%"}}>
            <Input
              readOnly={true}
              size="large"
              style={{
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.publisher_cpm ? `$${publisherData.publisher_cpm}` : publisherData.publisher_cpm}
            />
          </Form.Item>
          <Form.Item label="Serving Fee" style={{  width: "20%"}}>
            <Input
              readOnly={true}
              size="large"
              style={{
                marginBottom: 22,
                boxShadow: "none",
                border: "0.1px solid #ccc",
              }}
              value={publisherData.publisher_serving_fee ? `$${publisherData.publisher_serving_fee}`: publisherData.publisher_serving_fee}
            />
          </Form.Item>

          <Form.Item label="Revenue Source" style={{  width: "20%"}}>
          <Input
              readOnly={true}
              size="large"
              style={{
                marginBottom: 22,
                boxShadow: "none",
                border: "0.1px solid #ccc",
                textTransform: "capitalize"
              }}
              value={publisherData.revenue_source === "bi" ? publisherData.revenue_source.toLocaleUpperCase() : publisherData.revenue_source}
            />            
          </Form.Item>
        </Row>


      </Form>

      <DomainsTable
        pub_id={publisherData.pub_id}
        pub_key={publisherData.public_key}
      />
    </div>
  );
};

export default PublisherDetails;
